import { Injectable } from '@angular/core';
import { popupStatus } from '../enums/Enums';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogStatusService {
  private modalStatusSubject = new BehaviorSubject<popupStatus>('closed');

  constructor() { }

  get currentPopupStatus() {
    return this.modalStatusSubject.value;
  }

  setPopupStatus(status: popupStatus) {
    this.modalStatusSubject.next(status);
  }

  currentPopupStatusObserver() {
    return this.modalStatusSubject.asObservable();
  }
}
