import { Pipe, PipeTransform } from '@angular/core';
import { descModalidadeCashback, emodalidade_cashback } from '../enums/Enums';

@Pipe({
  name: 'modalidadeCashback',
  standalone: true,
})
export class ModalidadeCashbackPipe implements PipeTransform {
  transform(value?: emodalidade_cashback, short?: boolean): string {
    if (value) {
      if(short) {
        switch(value) {
          case 'PONTOS':
            return 'Pontos';
          case 'VALOR':
            return 'Cashback';
          case 'AMBAS':
            return 'Pontos e Cashback';
            default:
            return value;
        }
      }
      return descModalidadeCashback[value];
    }
    return '';
  }
}
