import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRole',
  standalone: true
})
export class UserRolePipe implements PipeTransform {

  transform(key?: string): unknown {
    if(key === 'USER') return 'Usuário';
    if(key === 'MANAGER') return 'Gerente';
    if(key === 'ADMIN') return 'Administrador';
    if(key === 'CUSTOMER') return 'Cliente';

    return key;
  }

}
